import * as React from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import { useEffect, useRef, useState } from "react"
import moment from "moment"
import Layout from "../Layout/layout"
import Seo from "../seo"
import AxiosInstance from "../../services/axiosInstance"
import EventModal from "./eventModal"
import { DATE_FORMAT } from "./eventHelper"
import MenuContainer from "./menuContainer"
import TagListView from "./Agenda/tagListView"

const Calendar = ({ addEvent, calendarView }) => {
  if (!["dayGridMonth", "timeGridWeek", "timeGridDay"].includes(calendarView)) {
    calendarView = "dayGridMonth"
  }
  const [showModal, setShowModal] = useState(addEvent)
  const [selectedEventTime, setSelectedEventTime] = useState("")
  const [contentHeight, setContentHeight] = useState(window.innerHeight)
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [showGoogleLogin, setShowGoogleLogin] = useState(false)
  const [timeZoneValue, setTimeZoneValue] = useState("")
  const [currentMonthYear, setCurrentMonthYear] = useState("")
  const [eventFromDate, setEventFromDate] = useState("")
  const [eventToDate, setEventToDate] = useState("")

  const calendarRef = useRef()

  const getEvents = async (fetchInfo, successCallback) => {
    try {
      if (fetchInfo) {
        const fromDate = moment(fetchInfo.start).format("YYYY-MM-DD")
        const toDate = moment(fetchInfo.end).format("YYYY-MM-DD")

        const response = await AxiosInstance.get(`/calendar?from_date=${fromDate}&to_date=${toDate}`)
        if (response.status === 200 && successCallback) {
          if (eventFromDate === "") {
            setEventFromDate(fromDate)
          }

          if (eventToDate === "") {
            setEventToDate(toDate)
          }

          successCallback(
            response.data.items.map(event => {
              event.start = event.startTime
              event.end = event.endTime
              event.url = event.task ? `/jobs/detail/${event.task.id}` : `/calendar/detail/${event.id}`
              event.extendedProps = {
                duration: moment(event.endTime).diff(moment(event.startTime), "minutes"),
                metaJson: event.metaJson,
              }

              return event
            })
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleEventDrop = eventInfo => {
    AxiosInstance.post(`/calendar/edit/${eventInfo.event.id}`, {
      title: eventInfo.event.title,
      startTime: moment(eventInfo.event.start).format(DATE_FORMAT),
      endTime: moment(eventInfo.event.start)
        .add(eventInfo.event.extendedProps.duration, "minutes")
        .format(DATE_FORMAT),
      eventType: "Service",
    }).catch(function (error) {
      console.log(error)
    })
  }

  useEffect(() => {
    calendarRef.current.getApi().changeView(calendarView)
  })

  const handleDateClick = arg => {
    setSelectedEventTime(moment(arg.date).format(DATE_FORMAT))
    setShowModal(true)
  }

  const renderEventContent = eventInfo => {
    const eventStartTime = eventInfo.event.start.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })

    return (
      <b>
        {eventStartTime.toLowerCase()}{" "}
        <i className={`bi bi-${eventInfo.event.extendedProps.task?.transactionType?.icon}`}></i>{" "}
        {eventInfo.event.title}{" "}
        {eventInfo.event.extendedProps.metaJson.event_tags?.length > 0 && (
          <TagListView
            tags={eventInfo.event.extendedProps.metaJson.event_tags}
            eventId={eventInfo.event.id}
          />
        )}
        {eventInfo.event.extendedProps.task?.tags.length > 0 && (
          <TagListView
            tags={eventInfo.event.extendedProps.task?.tags}
            taskId={eventInfo.event.extendedProps.task?.id}
          />
        )}
      </b>
    )
  }

  useEffect(() => {
    const updateHeight = () => {
      const tabsHeight = document.querySelector(".docsDriveTabs")?.offsetHeight || 0
      const headingHeight = document.querySelector(".headingPartTop")?.offsetHeight || 0
      const newHeight = window.innerHeight - tabsHeight - headingHeight - 90
      setContentHeight(newHeight)
    }

    updateHeight()
    window.addEventListener("resize", updateHeight)
    return () => {
      window.removeEventListener("resize", updateHeight)
    }
  }, [])

  const loadGoogleAPI = () => {
    const script = document.createElement("script")
    script.src = "https://accounts.google.com/gsi/client"
    script.onload = () => {
      import("gapi-script").then(({ gapi }) => {
        gapi.load("client:auth2", () => {
          gapi.auth2.init({
            client_id: process.env.GATSBY_GOOGLE_CLIENT_ID,
          })

          const authInstance = gapi.auth2.getAuthInstance()
          authInstance.isSignedIn.listen(updateSignInStatus)
          updateSignInStatus(authInstance.isSignedIn.get())
        })
      })
    }
    document.body.appendChild(script)
  }

  const updateSignInStatus = signedIn => {
    setIsSignedIn(signedIn)
  }

  // const handleGoogleSignIn = () => {
  //   import("gapi-script").then(({ gapi }) => {
  //     const auth2 = gapi.auth2.getAuthInstance()
  //     auth2
  //       .signIn({
  //         scope: "https://www.googleapis.com/auth/calendar",
  //       })
  //       .then(() => {
  //         setIsSignedIn(true)
  //       })
  //   })
  // }

  const getAllEvents = async () => {
    try {
      const response = await AxiosInstance.get(`/calendar?from_date=${eventFromDate}&to_date=${eventToDate}`)
      if (response.status === 200) {
        return response.data.items
      }
    } catch (error) {
      console.log(error)
    }
  }

  const syncWithGoogleCalendar = () => {
    if (!isSignedIn && !showGoogleLogin) {
      setShowGoogleLogin(true)
      return
    }

    try {
      import("gapi-script").then(({ gapi }) => {
        const auth2 = gapi.auth2.getAuthInstance()
        auth2
          .signIn({
            scope: "https://www.googleapis.com/auth/calendar",
          })
          .then(async () => {
            const accessToken = auth2.currentUser.get().getAuthResponse().access_token
            if (!accessToken) {
              return
            }

            const events = await getAllEvents()
            // const events = calendarRef.current.getApi().getEvents()

            if (events.length > 0) {
              events.forEach(event => {
                const googleEvent = {
                  summary: event.title,
                  start: {
                    dateTime: event.startTime,
                    timeZone: timeZoneValue,
                  },
                  end: {
                    dateTime: event.endTime,
                    timeZone: timeZoneValue,
                  },
                }

                const eventPath = `https://www.googleapis.com/calendar/v3/calendars/primary/events${
                  event?.googleCalendarId ? "/" + event.googleCalendarId : ""
                }`
                const eventMethod = event?.googleCalendarId ? "PUT" : "POST"

                gapi.client
                  .request({
                    path: eventPath,
                    method: eventMethod,
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(googleEvent),
                  })
                  .then(response => {
                    if (eventMethod === "POST") {
                      AxiosInstance.post(`/calendar/save-google-calender-id/${event.id}`, {
                        googleCalendarId: response.result.id,
                      }).catch(function (error) {
                        console.log(error)
                      })
                    }

                    getAllEvents()
                    setShowGoogleLogin(false)
                  })
                  .catch(error => {
                    console.error("Error syncing event:", error)
                  })
              })
            } else {
              console.log("No events found or events is not an array.")
            }
          })
      })
    } catch (error) {
      console.error("Error syncing with Google Calendar:", error)
    }
  }

  const getTimeZone = async () => {
    await AxiosInstance.get("/settings/personal-setting/language-data").then(response => {
      response.data.data.map(data => {
        if (data.type === "TimeZone") {
          setTimeZoneValue(data.value)
        }
      })
    })
  }

  const updateMonthYear = () => {
    const calendarApi = calendarRef.current.getApi()
    const currentDate = calendarApi.getDate()
    const formattedDate = currentDate.toLocaleString("default", { month: "long", year: "numeric" })
    setCurrentMonthYear(formattedDate)
  }

  const handleCalendarNavigation = action => {
    const calendarApi = calendarRef.current.getApi()
    if (action === "prev") calendarApi.prev()
    if (action === "next") calendarApi.next()
    if (action === "today") calendarApi.today()
    updateMonthYear()
  }

  useEffect(() => {
    loadGoogleAPI()
    getTimeZone()
    updateMonthYear()
  }, [])

  return (
    <Layout>
      <Seo title="Calendar" />
      <div id="right-section" className="h-100">
        <div className="row g-0" id="eventList">
          <div className="col" id="page-urls">
            <div className={`row GoogleSyncContainer${!showGoogleLogin ? " d-none" : ""}`}>
              <div className="col-lg-4 mx-auto">
                <div className="row">
                  <div className="col-lg-12 colHeading">
                    <strong>
                      <i className="bi bi-google" /> Google Sync
                    </strong>
                    <button className="btn btnCloseGoogleSync" onClick={() => setShowGoogleLogin(false)}>
                      <i className="bi bi-x-lg" />
                    </button>
                  </div>
                  <div className="col-lg-12 dh-block-body">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <div className="loginWithSocialToggle mx-1" data-auth="Google">
                          <button className="btn btnGoogleOutline" onClick={syncWithGoogleCalendar}>
                            <i className="bi bi-check2" /> Connect to Google <i className="bi bi-google" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <MenuContainer
              setShowModal={setShowModal}
              page={calendarView}
              syncWithGoogleCalendar={syncWithGoogleCalendar}
            />

            <div className="row">
              <div className="col-lg-12">
                <div className="headingPartTop text-center clearfix">
                  <div className="centerElement">
                    <div className="button-group">
                      <button
                        type="button"
                        className="btn calendarBtn c-prev-button mb-0"
                        aria-label="prev"
                        onClick={() => handleCalendarNavigation("prev")}
                      >
                        <i className="bi bi-chevron-left"></i>
                      </button>
                      <h4 className="mx-2 mb-0 currentMonthYear">
                        <strong>{currentMonthYear}</strong>
                      </h4>
                      <button
                        type="button"
                        className="btn calendarBtn c-next-button mb-0"
                        aria-label="next"
                        onClick={() => handleCalendarNavigation("next")}
                      >
                        <i className="bi bi-chevron-right"></i>
                      </button>

                      <button
                        type="button"
                        className="todayBtn"
                        onClick={() => handleCalendarNavigation("today")}
                      >
                        today
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              contentHeight={contentHeight}
              initialView={calendarView}
              dateClick={handleDateClick}
              eventContent={renderEventContent}
              headerToolbar={{
                left: "",
                center: "",
                right: "",
              }}
              events={(fetchInfo, successCallback, failureCallback) =>
                getEvents(fetchInfo, successCallback, failureCallback)
              }
              editable
              eventDrop={handleEventDrop}
            />
          </div>
          {showModal && (
            <EventModal
              showModal={showModal}
              setShowModal={setShowModal}
              selectedEventTime={selectedEventTime}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Calendar
